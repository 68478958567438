@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;1,400;1,500&display=swap);
@import url(https://www.inngest.com/assets/fonts.css);
:root {
  --blue-left: #0f111e;
  --blue-right: #17273b;
  --font: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  --font-heading: "Real Text Pro", var(--font);
  --font-monospace: Menlo, Monaco, source-code-pro, Consolas, "Courier New",
    monospace;

  /* Inngest Design System Color Palatte - March 2022 */
  --color-purple: #4636f5;
  --color-purple-alpha-80: #4636f5cc; /* 80% alpha */
  --color-turquoise: #36f5de;
  --color-green: #9dcc3a;
  --color-teal: #89DDFF;
  --color-dark-green: #2c3910;
  --color-orange: #d3a347;
  --color-red: #d34747;
  --color-red-alpha-80: #d34747cc;
  --color-white: #ffffff;
  --color-gray-100: #fbfcfc;
  --color-gray-200: #9e9e9e;
  --color-gray-300: #535353;
  --color-gray-400: #303030;
  --color-gray-600: #3d3d3d;
  --color-gray-800: #242424;
  --color-gray-900: #171717;
  --color-gray-950: #1d1c1d;
  --color-dark-gradient-dark: #0e0e0e;
  --color-dark-gradient-light: #1a1a1a;
  --color-dark-gradient: var(--color-dark-gradient-dark)
    radial-gradient(
      72.94% 141.99% at 93.55% 5.67%,
      var(--color-dark-gradient-light) 0%,
      var(--color-dark-gradient-dark) 100%
    );
  --color-black: #000000;

  --color-primary: var(--color-purple);
  --color-primary-transparent: var(--color-purple-alpha-80);
  --color-success: var(--color-green);
  --color-error: var(--color-red);
  --color-error-transparent: var(--color-red-alpha-80);

  --color-d: var(--color-white);
  --color-l: var(--color-black);
  --color-dim-d: var(--color-gray-200);
  --color-dim-l: var(--color-gray-900);

  --bg-d: var(--color-dark-gradient-dark);
  --bg-l: #fffefc;
  --bg-alt-d: var(--color-black);
  --bg-alt-l: var(--color-gray-100);
  --menu-bg-d: var(--bg-d);
  --menu-bg-l: var(--bg-l);
  --panel-bg-d: var(--bg-d);
  --panel-bg-l: #fcfbf8;
  --menu-active-bg-l: #f9f3e6;
  --menu-active-bg-d: var(--color-gray-600);
  --box-bg-d: var(--color-gray-900);
  --box-bg-l: var(--color-white);
  --warning-bg-d: var(--color-red);
  --warning-bg-l: #fff2f0;

  --border-color-d: var(--color-gray-400);
  --border-color-l: #eaeae9;
  --input-border-color-d: var(--color-gray-600);
  --input-border-color-l: #eaeae9;
  --border-color-focus-d: var(--color-gray-400);
  --border-color-focus-l: #eaeae9;
  --border-color-warning-d: var(--color-red);
  --border-color-warning-l: #ffccc7;
  --box-border-color-d: var(--color-gray-800);
  --box-border-color-l: var(--color-gray-400);

  --link-color-d: var(--color-white);
  --link-color-l: var(--color-black);
  --nav-link-color-d: var(--color-gray-200);
  --nav-link-color-l: var(--color-gray-900);
  --nav-link-color-active-d: var(--color);
  --nav-link-color-active-l: var(--color);
  --dots-d: #6a6c79;
  --dots-l: #333;
  --box-shadow-d: rgba(255, 255, 255, 0.15);
  --box-shadow-l: rgba(0, 0, 0, 0.15);

  --text-green-l: var(--color-green);
  --text-green-d: var(--color-green);

  --transition: all 0.3s;
  --border-radius: 4px;

  /* Buttons */
  --button-disabled-color: var(--color-gray-300);

  --button-text-color-d: var(--white);
  --button-text-color-l: var(--black);
  --button-disabled-text-color: var(--color-gray-400);
  --button-border-width: 2px;
  --button-border-default: var(--color-gray-400);
  --button-border-default-hover: var(--color-gray-200);

  /* Tags */
  --tag-bg-d: var(--color-gray-400);
  --tag-bg-l: var(--color-gray-200);
  --tag-text-d: var(--color-white);
  --tag-text-l: var(--color-black);

  /* Code tags */
  --code-bg-d: var(--color-gray-800);
  --code-bg-l: var(--color-gray-200);

  /* Padding and Margins */
  --page-horizontal-spacing: 40px;
  --page-vertical-spacing: 40px;

  /* global vars (dark mode is default) */

  --bg: var(--bg-d);
  --bg-alt: var(--bg-alt-d);
  --color: var(--color-d);
  --color-dim: var(--color-dim-d);
  --border-color: var(--border-color-d);
  --border-color-focus: var(--border-color-focus-d);
  --border-color-warning: var(--border-color-warning-d);
  --menu-bg: var(--menu-bg-d);
  --panel-bg: var(--panel-bg-d);
  --box-bg: var(--box-bg-d);
  --box-border-color: var(--box-border-color-d);
  --menu-active-bg: var(--menu-active-bg-d);
  --warning-bg: var(--warning-bg-d);
  --link-color: var(--link-color-d);
  --nav-link-color: var(--nav-link-color-d);
  --nav-link-color-active: var(--nav-link-color-active-d);
  --dots: var(--dots-d);
  --box-shadow: var(--box-shadow-d);
  --text-green: var(--text-green-d);
  --input-border-color: var(--input-border-color-d);
  --button-text-color: var(--button-text-color-d);
  --tag-bg: var(--tag-bg-d);
  --tag-text: var(--tag-text-d);
  --code-bg: var(--code-bg-d);

  --modal-bg-opacity: 0.6;
}

:root.light {
  --bg: var(--bg-l);
  --bg-alt: var(--bg-alt-l);
  --color: var(--color-l);
  --color-dim: var(--color-dim-l);
  --border-color: var(--border-color-l);
  --border-color-focus: var(--border-color-focus-l);
  --border-color-warning: var(--border-color-warning-l);
  --menu-bg: var(--menu-bg-l);
  --panel-bg: var(--panel-bg-l);
  --box-bg: var(--box-bg-l);
  --box-border-color: var(--box-border-color-l);
  --menu-active-bg: var(--menu-active-bg-l);
  --warning-bg: var(--warning-bg-l);
  --link-color: var(--link-color-l);
  --nav-link-color: var(--nav-link-color-l);
  --nav-link-color-active: var(--nav-link-color-active-l);
  --dots: var(--dots-l);
  --box-shadow: var(--box-shadow-l);
  --text-green: var(--text-green-l);
  --input-border-color: var(--input-border-color-l);
  --button-text-color: var(--button-text-color-l);
  --tag-bg: var(--tag-bg-l);
  --tag-text: var(--tag-text-l);
  --code-bg: var(--code-bg-l);
  --modal-bg-opacity: 0.25;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
}

html {
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-family: var(--font);
}

body {
  overflow-x: hidden;
}

b,
strong {
  font-weight: 500;
}

i,
em {
  font-style: italic;
}

input,
button {
  font-size: 100%;
  outline: 0;
}

a,
button {
  cursor: pointer;
}

body {
  background: #0e0e0e;
  background: var(--bg);
  margin: 0;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-family: var(--font);
  -webkit-font-feature-settings: "liga" 1, "ss01" 1, "cv04" 1, "cv02" 1;
          font-feature-settings: "liga" 1, "ss01" 1, "cv04" 1, "cv02" 1;
  font-size: 14px;
  line-height: 1.6;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #ffffff;

  color: var(--color);
}

code {
  font-family: Menlo, Monaco, source-code-pro, Consolas, "Courier New",
    monospace;
  font-family: var(--font-monospace);
}
code.code-inline {
  background-color: #242424;
  background-color: var(--code-bg);
  border-radius: 4px;
  border-radius: var(--border-radius);
  padding: 0.2em 0.4em;
}
.mono {
  font-family: Menlo, Monaco, source-code-pro, Consolas, "Courier New",
    monospace;
  font-family: var(--font-monospace);
}

h1, h2, h3, h4, h5 {
  font-family: "Real Text Pro", Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-family: var(--font-heading);
}

h1 {
  font-size: 23px;
  font-weight: bold;
}

h2 {
  font-size: 26px;
  padding-bottom: 5px;
}

h3,
.h3 {
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 5px;
}

h3.underline,
.h3.underline {
  border-bottom: 1px solid #303030;
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 20px;
}

h4 {
  font-size: 16px;
}

h5 {
  font-size: 12px;
  opacity: 0.6;
}

.uppercase {
  text-transform: uppercase;
}

small.uppercase {
  opacity: 0.5;
}

input,
textarea,
select {
  background: #000000;
  background: var(--bg-alt);
  border-radius: 4px;
  border-radius: var(--border-radius);
  border: 2px solid #3d3d3d;
  border: 2px solid var(--input-border-color);
  color: #ffffff;
  color: var(--color);
  transition: all 0.3s;
  width: 100%;
  padding: 11px 14px 10px;
}

::-webkit-input-placeholder {
  color: #9e9e9e;
  color: var(--color-dim);
}

:-ms-input-placeholder {
  color: #9e9e9e;
  color: var(--color-dim);
}

::placeholder {
  color: #9e9e9e;
  color: var(--color-dim);
}

select {
  min-height: 42px;
}

select:focus,
input:focus,
textarea:focus {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);
  background: #0e0e0e;
  background: var(--menu-bg);
  border-color: #303030;
  border-color: var(--border-color-focus)
}

button {
  border: 1px solid #303030;
  border: 1px solid var(--border-color-focus);
  border-radius: 2px;
}

small {
  font-size: 11px;
}

a {
  color: #ffffff;
  color: var(--link-color);
}

label {
  /* color: var(--color-dim); */
  display: block;
  font-size: 0.9rem;
}

label + label,
label + button,
input + input {
  margin-top: 30px;
}

label input,
label select,
label textarea {
  margin-top: 8px;
  font-size: 1rem;
}

label small {
  display: block;
  opacity: 0.6;
}
label small:first-of-type {
  margin-top: 2px;
}
label small:last-of-type {
  margin-bottom: 3px;
}

hr {
  margin: 24px 0;
  border-bottom: 1px solid #303030;
  border-bottom: 1px solid var(--border-color);
}

.react-tiny-popover-container {
  z-index: 99;
}

.react-flow__node-blankExpression {
  pointer-events: none !important;
}

.react-flow__edge-path {
  stroke: #303030 !important;
  stroke: var(--border-color-focus) !important;
}

ol li + li {
  margin-top: 0.5rem;
}

/** react-split-pane **/
.Resizer {
  background: #000;
  opacity: 0.05;
  z-index: 1;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}

body .woot-elements--right {
  right: auto !important;
  left: 24px !important;
  bottom: 60px !important;
}

body .woot-widget-bubble img {
  display: none !important;
}

/* open button */
body .woot-widget-bubble {
  background: transparent !important;
  background: #0e0e0e !important;
  background: var(--bg) !important;
  border: 1px solid #303030;
  border: 1px solid var(--border-color-focus);
  width: 174px !important;
  height: 2.5rem !important;
  transition: all 0.3s;
}

body .woot-widget-bubble::after {
  content: "Chat with support";
  display: block;
  color: #ffffff;
  color: var(--color);
  opacity: 0.8;
  transition: all 0.3s;
}

/* close button */
body .woot-widget-bubble.woot--close::after {
  content: "";
}
body .woot-widget-bubble.woot--close {
  background: #17273b !important;
  background: var(--blue-right) !important;
  width: 64px !important;
  height: 64px !important;
  transition: all 0.3s;
}
body .woot-widget-holder {
  bottom: 130px !important;
}

body .woot-widget-bubble:hover::after {
  opacity: 1;
}

body .woot--hide.woot-widget-bubble {
  display: none !important;
}

/* Aim to match Button.tsx style */
body .Dropdown-control,
body .Dropdown-option {
  padding: 11px 14px 10px;
}
body .Dropdown-control {
  display: flex;
  justify-content: space-between;
  line-height: calc(1em + 1px);
  font-weight: 500;
  border: 2px solid #303030;
  border: var(--button-border-width) solid var(--border-color);
  border-radius: 4px;
  border-radius: var(--border-radius);

  cursor: pointer;
  font-family: inherit;
  color: inherit;
  background: transparent;
}

body .Dropdown-arrow-wrapper {
  max-height: calc(1em + 1px);
}

body .Dropdown-option {
  color: inherit;
}

body .Dropdown-option.is-selected {
  color: #0e0e0e;
  color: var(--bg-d);
}

body .Dropdown-menu {
  margin: -2px 4px 0;
  width: calc(100% - 8px);
  background: #0e0e0e;
  background: var(--menu-bg);
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.12);
  border-radius: 0 0 4px 4px;
  border-radius: 0 0 var(--border-radius) var(--border-radius);
  border: 2px solid #303030;
  border: var(--button-border-width) solid var(--border-color);
}

body .Dropdown-option:hover {
  background: #0e0e0e;
  background: var(--bg);
  background: #3d3d3d;
  background: var(--menu-active-bg);
  color: inherit;
}

/* rc-tooltip customization */
.rc-tooltip-inner {
  padding: 4px 8px;
  min-height: 26px;
  background-color: rgba(0,0,0,0.85);
}
.rc-tooltip-placement-right .rc-tooltip-arrow,
.rc-tooltip-placement-rightTop .rc-tooltip-arrow,
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  border-right-color: rgba(0,0,0,0.85);
}
.rc-tooltip-placement-left .rc-tooltip-arrow,
.rc-tooltip-placement-leftTop .rc-tooltip-arrow,
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  border-left-color: rgba(0,0,0,0.85);
}
.rc-tooltip-placement-top .rc-tooltip-arrow,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  border-top-color: rgba(0,0,0,0.85);
}
